<template>
  <div>
    <v-app-bar color="#6F3A9F" dark>
      <v-btn
        style="position: absolute; right: 20px; font-size: 20px"
        color="green"
        @click="
          add = true;
          addtoken = '';
          msgtype = '';
          errMsg = '';
        "
        >ADD
      </v-btn>
    </v-app-bar>
    <div class="table-container">
      <v-simple-table class="table-95" fixed-header>
        <thead>
          <tr>
            <th class="th-35 text-center"><h2>Token</h2></th>
            <th class="th-25 text-center"><h2>Payload</h2></th>
            <th class="th-40 text-center"></th>
          </tr>
        </thead>
        <tbody class="table-95">
          <tr v-for="item in channellist" :key="item.i">
            <td>{{ item.channel }}</td>
            <td>
              <div
                v-if="
                  $store.state.devices.find(
                    (obj) => obj.topic === item.channel
                  ) !== undefined
                "
              >
                {{
                  $store.state.devices.find((obj) => obj.topic === item.channel)
                    .payload
                }}
              </div>
            </td>
            <td >
            <v-btn
              color="orange"
              @click="
                editpayload = $store.state.devices.find(
                  (obj) => obj.topic === item.channel
                ).payload;
                edittoken = item.channel;
                edit = true;
              "
              >Edit</v-btn
            >
            <v-btn
              style="margin-left: 10px"
              :color="item.EnableLog ? 'red' : 'green'"
              @click="edit_log(item)"
            >
              {{ item.EnableLog ? "Disable Log" : "Enable Log" }}
            </v-btn>
            <v-btn style="margin-left: 10px" color="red" @click="remove(item)"
              >delete</v-btn
            >
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <v-dialog v-model="add" width="70%">
      <v-card
        style="overflow: hidden"
        elevation="16"
        class="mx-auto"
        height="30%"
      >
        <error-handler
          :errorMsg="errMsg"
          :msgtype="msgtype"
          v-if="errMsg != ''"
        />
        <v-card-title style="background-color: #803d9a"
          >Add Channel</v-card-title
        >
        <v-card-title>
          {{ this.$store.state.user.token }} /
          <v-text-field
            v-model="addtoken"
            :label="'topic'"
            style="padding: 100; margin-top: 100; font-size: 20px"
          ></v-text-field>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="addchanel">Comfirm</v-btn>
          <v-btn color="primary" text @click="add = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit" width="70%">
      <v-card
        style="overflow: hidden"
        elevation="16"
        class="mx-auto"
        height="30%"
      >
        <v-card-title style="background-color: #803d9a"
          >Edit Payload</v-card-title
        >
        <v-card-title>
          <v-text-field
            v-model="editpayload"
            :label="'payload'"
            style="padding: 100; margin-top: 100; font-size: 20px "
          ></v-text-field>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click.stop="edit_payload()">send</v-btn>
          <v-btn color="primary" text @click="edit = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ErrorHandler from "../components/ErrorHandler.vue";
import { mapActions } from "vuex";

export default {
  components: {
    ErrorHandler,
  },
  props: ["devices"],
  data() {
    return {
      errMsg: "",
      msgtype: "",
      found: null,
      channellist: [],
      add: false,
      addtoken: "",
      edit: false,
      editpayload: "",
      edittoken: "",
    };
  },
  methods: {
    ...mapActions(["doPublish", "doConnection"]),
    edit_payload() {
      this.doPublish({
        topic: this.edittoken,
        payload: this.editpayload,
      });
    },
    async channelupdate() {
      await axios
        .get(
          this.$store.state.serverUrl +
            "channel/" +
            this.$store.state.user.token
        )
        .then((response) => {
          return response;
        })
        .then((data) => {
          this.channellist = data.data;
          for (let j = 0; j < this.channellist.length; j++) {
            let newData = { payload: "" };
            Object.assign(this.channellist[j], newData);
          }
          this.$store.state.channel = this.channellist;
          this.doConnection();
        });
    },
    async remove(item) {
      console.log("Delete ", item.channel);
      await axios
        .get(this.$store.state.serverUrl + "channelremove/" + item.channel)
        .then((response) => {
          return response;
        })
        .then(() => {
          this.channelupdate();
        });
    },
    async addchanel() {
      this.errMsg = "";
      console.log(
        "Add Channel ",
        this.$store.state.user.token + "/" + this.addtoken
      );
      await axios
        .get(
          this.$store.state.serverUrl +
            "channeladd/" +
            this.$store.state.user.token +
            "/" +
            this.addtoken
        )
        .then((response) => {
          return response;
        })
        .then((data) => {
          console.log(data);
          if (data.data.err) {
            this.msgtype = "error";
            this.errMsg = data.data.err;
          } else {
            this.msgtype = "success";
            this.errMsg = data.data.success;
            this.channelupdate();
          }
        });
    },
    async edit_log(item) {
      console.log("edit_log ", item.channel);
      await axios
        .get(
          this.$store.state.serverUrl +
            "editlog/" +
            item.channel
        )
        .then((response) => {
          return response;
        })
        .then((data) => {
          console.log(data);
          this.channelupdate();
        });
    },
  },
  async mounted() {
    this.channelupdate();
  },
};
</script>

<style>
.table-container {
  display: flex;
  justify-content: center; /* Centers the table horizontally */
  align-items: top; /* Centers the table vertically */
  height: 100vh; /* Full height of the viewport */
  text-align: center;
}
.table-95 {
  width: 95%;
  margin: 0 auto;
  text-align: center;
}
.th-40 {
  width: 40%;
  background-color: #333;
  text-align: center;
}
.th-35 {
  width: 35%;
  text-align: center;
}
.th-25 {
  width: 25%;
  text-align: center;
}
</style>
