import Vue from "vue";
import Vuex from "vuex";
import * as mqtt from "mqtt";
import * as url from "url";
import axios from "axios";
//import { time } from 'console'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    channel: [],
    client: null,
    mqttUrl: "ws://167.99.76.255:8883",
    serverUrl: "http://bc-cloudiot.xyz:81/",
    //option: {username: 'guest' , password: 'guest'},
    devices: [], // {topic: xxx, payload:yyy ,timestamp:zzz}
    widgets: [
      // {id: 1, name: "Room1",pageId: 0,payload: 0,show: true,size: {w: 200,h: 200,x: 100,y: 100},timestamp: null,topic: "Zf9Do21B/device1/temp",type: "WeatherStation",deviceType: 'object'},
      // {id: 2, name: "Room2",pageId: 1,payload: 0,show: true,size: {w: 200,h: 200,x: 100,y: 100},timestamp: null,topic: "Zf9Do21B/device2/temp",type: "ToggleSwitch",deviceType: 'string'},
      // {id: 3, name: "Room3",pageId: 0,payload: 0,show: true,size: {w: 200,h: 200,x: 100,y: 100},timestamp: null,topic: "Zf9Do21B/device1",type: "WeatherStation",deviceType: 'object'},
      // {id: 3, name: "Room4",pageId: 0,payload: 0,show: true,size: {w: 200,h: 200,x: 100,y: 100},timestamp: null,topic: "Zf9Do21B/device2",type: "WeatherStation",deviceType: 'object'},
      // {id: 4, name: "LineNotify1",pageId: 3,payload: 0,show: true,size: {w: 200,h: 200,x: 100,y: 100},timestamp: null,topic: "Zf9Do21B/linenotify1",type: "WeatherStation",deviceType: 'object'},
      // {id: 5, name: "LineNotify2",pageId: 4,payload: 0,show: true,size: {w: 200,h: 200,x: 100,y: 100},timestamp: null,topic: "Zf9Do21B/linenotify2",type: "WeatherStation", deviceType: 'object'},
      // {id: 5, name: "Lamp1",pageId: 1,payload: 0,show: true,size: {w: 200,h: 200,x: 100,y: 100},timestamp: null,topic: "Zf9Do21B/device1/lamp",type: "WeatherStation",deviceType: 'object'},
    ],
    /*ruleEngine:
    [
      { rule: "ApexGauge1 > ApexGauge2", widget: "Lamp1", message: "1" , active : true},
      { rule: "ApexGauge1 <= ApexGauge2", widget: "Lamp1", message: "0" , active : true},
    ],*/
    pages: [],
    pageId: 0,
    widgettype: [
      //{ name: 'WeatherStation' , icon: 'mdi-weather-cloudy', deviceType: 'object'},
      { name: "ToggleSwitch", icon: "mdi-toggle-switch", deviceType: "string" },
      { name: "Step", icon: "mdi-counter", deviceType: "string" },
      {
        name: "SilderHorizontal",
        icon: "mdi-ray-vertex",
        deviceType: "string",
      },
      {
        name: "TextInput",
        icon: "mdi-message-text-fast-outline",
        deviceType: "string",
      },
      { name: "TimeInput", icon: "mdi-timer", deviceType: "string" },
      { name: "Lamp", icon: "mdi-lightbulb-outline", deviceType: "string" },
      { name: "ValueDisplay", icon: "mdi-numeric", deviceType: "string" },
      { name: "Gauge", icon: "mdi-gauge", deviceType: "string" },
      { name: "Chart", icon: "mdi-chart-areaspline", deviceType: "string" },
      // { name: "Chart_2", icon: "mdi-chart-areaspline", deviceType: "object" },
    ],
    style: { backgroundColor: "#ffffff" },
    edit: false,
    webtype: "",
  },
  mutations: {
    saveDevices(state, data) {
      var topic = data.topic;
      var payload = data.payload.toString("utf-8");
      var timestamp = new Date().toISOString();

      //console.log(state.user.token, topic);

      if (state.devices.length > 0) {
        var topicExists = state.devices.filter((f) => f.topic == topic);
        if (topicExists.length > 0) {
          topicExists[0].payload = payload;
          topicExists[0].timestamp = timestamp;
          topicExists[0].type = "string";
        } else {
          state.devices.push({
            topic: topic,
            payload: payload,
            timestamp: timestamp,
            type: "string",
          });
        }
        state.widgets
          .filter((f) => f.topic == topic)
          .forEach((w) => {
            w.payload = payload;
            w.topic = topic;
            w.timestamp = timestamp;
          });
        state.pages.forEach((wg) => {
          wg.BCDBWidgets.filter((f) => f.topic == topic).forEach((w) => {
            w.payload = payload;
            w.topic = topic;
            w.timestamp = timestamp;
          });
        });
      } else {
        state.devices.push({
          topic: topic,
          payload: payload,
          timestamp: timestamp,
          type: "string",
        });
      }

      //this.dispatch("doCheckRuleEngine")
    },
  },
  actions: {
    async doConnection({ state }) {
      console.log("doConnection");
      //state.client = mqtt.connect(url.parse(state.mqttUrl), state.option)

      // Disconnect existing connection if it exists
      if (state.client) {
        state.client.end();
        state.client = null;
      }

      state.client = mqtt.connect(url.parse(state.mqttUrl));

      state.client.on("connect", () => {
        console.log("doConnection", "connected", state.user.token);
        for (let j = 0; j < state.channel.length; j++) {
          state.client.subscribe(state.channel[j].channel);
          state.client.publish(
            state.user.token + "/Sync",
            state.channel[j].channel
          );
        }
      });

      state.client.on("message", (topic, payload) => {
        console.log(
          "doConnection",
          "massage",
          "topic",
          topic,
          "payload",
          payload.toString("utf-8")
        );

        this.commit("saveDevices", { topic: topic, payload: payload });
      });
    },
    doPublish({ state }, data) {
      var topic = data.topic;
      var payload = data.payload;
      state.client.publish(topic, payload);
    },
    saveAll({ state }) {
      console.log(state.widgets);
      var data = {
        devices: state.devices,
        widgets: state.widgets.filter((f) => f.show == true),
        //ruleEngine: state.ruleEngine,
        pages: state.pages,
        pageId: state.pageId,
        mqttUrl: state.mqttUrl,
        style: state.style,
      };
      //console.log(data);
      //localStorage.setItem(state.user.token + "/data", JSON.stringify(data));
      localStorage.setItem("user", JSON.stringify(state.user));

      const url = "http://bc-cloudiot.xyz:81/saveWidgets/" + state.user.token;
      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(() => {
          // Handle the response if needed
          //console.log(response.data);
        });

      state.edit = false;
    },
    isMobileDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileUserAgent =
        /android|iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile|WPDesktop/i.test(
          userAgent
        );
      const isSmallScreen = window.innerWidth <= 900;
      return isMobileUserAgent || isSmallScreen;
    },
    async loadAll({ state, dispatch }) {
      var user = localStorage.getItem("user");
      if (user != null && user != "") {
        state.user = JSON.parse(user);
      }

      if (state.user != null) {
        console.log("loadAll", state.user);

        var SuccessLoad;

        await axios
          .get("http://bc-cloudiot.xyz:81/loadWidgets/" + state.user.token)
          .then((response) => {
            SuccessLoad = response.data;
          });

        console.log(SuccessLoad);
        if (SuccessLoad != undefined) {
          console.log("True");
          console.log(await dispatch("isMobileDevice"));

          // ตรวจสอบประเภทอุปกรณ์ด้วยฟังก์ชัน isMobileDevice
          const isMobile = await dispatch("isMobileDevice");
          if (isMobile == true) {
            console.log("Mobile");
            state.webtype = "Mobile";

            // เรียง widget ในแนวตั้งสำหรับอุปกรณ์มือถือ
            var last_y = 10; // เริ่มต้นที่ตำแหน่ง y ของ widget แรก
            SuccessLoad.widgets.forEach((wg) => {
              if (wg.type === "Chart") {
                wg.size.h = 380; // กำหนดความสูงสำหรับ chart
              } else if (wg.type == "ValueDisplay") {
                wg.size.h = 140; // กำหนดความสูงสำหรับ ValueDisplay
              } else {
                wg.size.h = 200; // ความสูงทั่วไปสำหรับ widget อื่น ๆ
              }

              // กำหนดความกว้างเต็มหน้าจอ และจัดเรียง widget ในแนวตั้ง
              wg.size.w = window.innerWidth - 40; // กำหนดความกว้างเต็มหน้าจอโดยมีระยะห่างด้านข้าง
              wg.size.x = 20; // กำหนดให้ widget อยู่กึ่งกลางแนวนอน
              wg.size.y = last_y; // จัดตำแหน่ง y ของ widget
              last_y += wg.size.h + 5; // ปรับค่า y สำหรับ widget ถัดไป

              console.log(wg);
            });
          } else {
            console.log("PC");
            state.webtype = "PC";
          }

          state.devices = SuccessLoad.devices;
          state.widgets = SuccessLoad.widgets;
          state.pages = SuccessLoad.pages;
          state.pageId = SuccessLoad.pageId;
          state.mqttUrl = SuccessLoad.mqttUrl || "ws://167.99.76.255:8883";
          state.style = SuccessLoad.style;

          await axios
            .get(state.serverUrl + "channel/" + state.user.token)
            .then((response) => {
              return response;
            })
            .then((data) => {
              state.channel = data.data;
              for (let j = 0; j < state.channel.length; j++) {
                let newData = { payload: "" };
                Object.assign(state.channel[j], newData);
              }
            });

          // ลบ widget ที่มี id ตรงกับ widgetId ออก
          state.widgets = state.widgets.filter(
            (widget) => widget.show === true
          );

          // อัปเดต id ให้เรียงลำดับใหม่ตามตำแหน่งใน array
          state.widgets.forEach((widget, index) => {
            widget.id = index + 1; // อัปเดต id ใหม่โดยให้เริ่มที่ 1
          });
          console.log(state.widgets);
        } else {
          console.log("False"),
            (state.devices = []),
            (state.widgets = []),
            (state.pages = [{ id: 0, name: "page", show: true }]),
            (state.pageId = 0),
            (state.mqttUrl = "ws://167.99.76.255:8883");
        }
      } else {
        (state.devices = []),
          (state.widgets = []),
          (state.pages = [{ id: 0, name: "page", show: true }]),
          (state.pageId = 0);
      }
    },
    async channelupdate() {
      await axios
        .get(
          this.$store.state.serverUrl + "channel/" + this.$route.params.token
        )
        .then((response) => {
          return response;
        })
        .then((data) => {
          this.$store.state.channel = data.data;
          for (let j = 0; j < this.channellist.length; j++) {
            let newData = { payload: "" };
            Object.assign(this.$store.state.channel[j], newData);
          }
          console.log(this.$store.state.channel);
        });
    },
    /*checkNameChange({state}, data){
      var name = data.name
      var newName = data.newname
      state.ruleEngine.forEach(r => {
        r.rule = r.rule.replaceAll(name, newName)
        r.widget = r.widget.replaceAll(name, newName)
      })
    }*/
  },
  modules: {},
});
